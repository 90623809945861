import { Box, Button, Dropdown, Icon, Text } from '../../../shared/components';
import { ReactComponent as SmartText } from '../../../images/svg/smart-text.svg';
import { SmartFieldWrapper } from '../../../BriteEditor/toolbar-menus/toolbar';
import { useSlate } from 'slate-react';
import { TextAlignCenter, TextAlignLeft, TextAlignRight, TextBolder, TextItalic } from '@phosphor-icons/react';
import { updateInline } from './SlateProperties';
import { useCallback } from 'react';
import {
  getActiveBlock,
  getActiveMarks,
  isBlockActive,
  isInlineActive,
  removeAllMarks,
  toggleBlock,
  updateMark,
} from '../../../BriteEditor/editor-components/text-v2/slate-utils';
import { Tooltip } from '../../../common/components/Tooltip';
import { colors } from '../../../shared/styles';
import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { TYPOGRAPHY_MAP } from '../../utility/constants';
import { Modify } from '../../providers/content/content-modify';
import { useContent } from '../../providers/content/use-content';
import { Range } from 'slate';

const alignIconMap = {
  p: TextAlignLeft,
  left: TextAlignLeft,
  center: TextAlignCenter,
  right: TextAlignRight,
};

export const TextToolbar = ({ id, textType }) => {
  const { sendUpdates } = useContent();
  const { data: designStyles } = useEditorResource('styles');

  const slate = useSlate();
  const activeBlock = getActiveBlock(slate, ['ordered-list', 'unordered-list']);
  const activeMarks = getActiveMarks(slate);
  const currentTypography = activeMarks?.typography || textType;
  const { border, ...style } = designStyles?.text?.[currentTypography] || {};

  const updateTypography = (e, type) => {
    e.preventDefault();
    const isCollapsed = Range.isCollapsed(slate?.selection);
    if (isCollapsed) {
      removeAllMarks(slate, 'typography');
      Modify.properties(sendUpdates, id, () => ({ type }));
    } else {
      updateMark(slate, 'typography', type);
    }
  };

  const Inline = useCallback(
    ({ type, label, children }) => {
      const onClick = () => updateInline(slate, type);
      const isActive = isInlineActive(slate, type);
      return (
        <Button
          css={`
            transition: background-color 0.2s ease;
            ${isActive ? `outline: 1px solid ${colors.gray[500]};` : ''}
          `}
          styles="icon-dark sm"
          hoverLabel={label}
          onClick={onClick}
          onMouseDown={(e) => e.preventDefault()}
        >
          {children}
        </Button>
      );
    },
    [slate]
  );

  const StyleButton = useCallback(
    ({ type, label, children }) => {
      const onClick = () => toggleBlock(slate, type);
      const blockActive = isBlockActive(slate, type);
      return (
        <Tooltip label={label}>
          <Button
            css={`
              ${blockActive ? `outline: 1px solid ${colors.gray[500]};` : ''}
            `}
            styles="icon-dark sm"
            hoverLabel={label}
            onClick={onClick}
            onMouseDown={(e) => e.preventDefault()}
          >
            {children}
          </Button>
        </Tooltip>
      );
    },
    [slate]
  );

  const block = getActiveBlock(slate);

  const AlignIcon = alignIconMap?.[block?.type] || TextAlignLeft;

  return (
    <Box
      flex="left"
      css={`
        gap: 8px;
      `}
    >
      <Box
        flex="left"
        css={`
          gap: 4px;
        `}
      >
        <Dropdown
          clear
          listCss={`
            background-color: ${designStyles?.general?.backgroundColor};
            .option {
              :hover {
                background-color: ${designStyles?.general?.backgroundColor};
                filter: brightness(0.9);
              }
            }
          `}
          button={() => (
            <Button
              onMouseDown={(e) => e.preventDefault()}
              css={`
                border-radius: 16px;
                background-color: ${designStyles?.general?.backgroundColor};
                border: 1px solid ${style?.color};
                text-transform: none;
                padding: 8px 12px;
                transition: filter 0.3s ease;
                :hover {
                  filter: brightness(0.9);
                }
              `}
            >
              <Text
                style={{
                  ...style,
                  fontSize: '14px',
                  lineHeight: '16px',
                }}
              >
                Aa
              </Text>
            </Button>
          )}
        >
          <Box data-scope="menu">
            {Object.entries(TYPOGRAPHY_MAP).map(([key, label]) => (
              <Box
                option
                className="option"
                style={designStyles?.text?.[key]}
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e) => updateTypography(e, key)}
              >
                {label}
              </Box>
            ))}
          </Box>
        </Dropdown>

        <Inline type="bold" label="Bold">
          <TextBolder size={24} color="white" />
        </Inline>
        <Inline type="italic" label="Italic">
          <TextItalic size={24} color="white" />
        </Inline>
        {/* TODO: Decide if we want colorpicker in toolbar */}
        {/* <ColorPicker color={activeColor} update={onUpdateColor} label="Text Color" /> */}
      </Box>

      <Dropdown
        clear
        disabled={!!activeBlock?.type}
        button={() => (
          <Button styles="icon-dark sm">
            <AlignIcon size={24} color="currentColor" />
          </Button>
        )}
        listCss={`background-color: ${colors.gray[600]}; margin-top: 4px;`}
      >
        <Box
          flex="left"
          data-scope="menu"
          css={`
            gap: 8px;
          `}
        >
          <StyleButton type="left" label="Left">
            <TextAlignLeft size={24} color="currentColor" />
          </StyleButton>
          <StyleButton type="center" label="Center">
            <TextAlignCenter size={24} color="currentColor" />
          </StyleButton>
          <StyleButton type="right" label="Right">
            <TextAlignRight size={24} color="currentColor" />
          </StyleButton>
        </Box>
      </Dropdown>
      <Dropdown
        clear
        stopPropagation
        button={() => (
          <Button styles="icon-dark sm">
            <Icon SVG={SmartText} size={24} />
          </Button>
        )}
      >
        <Box
          data-scope="menu"
          css={`
            min-width: 400px;
            padding: 0 8px;
          `}
        >
          <SmartFieldWrapper />
        </Box>
      </Dropdown>
    </Box>
  );
};
