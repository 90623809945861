import { container, shadows } from '../../../shared/shared-styles';
import { Box, BriteLoader, Button, Input, Modal, Text, Toggle } from '../../../shared/components';
import { useEffect, useMemo, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { useDebounce } from '../../../shared/use-debounce';
import { CustomAxios } from '../../../redux/axios/axios';
import { useContent } from '../../providers/content/use-content';
import { Content } from '../../providers/content/Content';
import { toast } from 'react-toastify';
import { useStore } from '../../../store-provider/use-store';
import { useAcls } from '../../../shared/use-acls';
import { WRITE_COURSE } from '../../../shared/acl-constants';
import { useOptimistically } from '../../../react-query/use-resource';
import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { useToolKit } from '../../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../../providers/tool-kit/ToolKit';
import { colors } from '../../../shared/styles';
import { Collapse } from '../../../common/components/Collapse';
import { useQuery } from 'react-query';

const uploadScreenshot = async (canvas, name) => {
  try {
    // Get blob from screenshot
    const blob = await new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to convert canvas to blob'));
        }
      }, 'image/png');
    });

    // Upload screenshot and get media ID to use in section save
    const file = new File([blob], name, { type: 'image/png' });
    const filtype = encodeURIComponent(file.type.replace(/\//g, '.'));
    const url = `v2/media/${file.name}/${filtype}`;
    const formData = new FormData();
    formData.append('file', file);
    const config = { headers: { 'content-type': 'multipart/form-data' } };
    const response = await CustomAxios.post(url, formData, config);
    return response?.data;
  } catch {
    return '';
  }
};

const getShareScope = (data, selectedBusiness) => {
  if (data?.global) {
    return {
      business_id: selectedBusiness?.ID,
      share_scope: 'global',
    };
  } else if (data?.private) {
    return {
      business_id: selectedBusiness?.ID,
      share_scope: 'business',
    };
  } else {
    return {
      business_id: selectedBusiness?.ParentID,
      share_scope: 'inherited',
    };
  }
};

export const SaveSectionModal = ({ onClose }) => {
  const toolkit = useToolKit();
  const section = ToolKit.getModalData(toolkit);

  const {
    data: { selectedBusiness },
  } = useStore();

  const { content } = useContent();

  const query = useEditorResource('blocks');

  const optimisticQuery = useOptimistically(query?.queryKey);

  const canvasRef = useRef();
  const [screenshot, setScreenshot] = useState('');
  const [isLoadingImg, setIsLoadingImg] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const debounce_setIsLoadingImg = useDebounce(setIsLoadingImg, 500);
  const canWrite = useAcls([WRITE_COURSE], selectedBusiness?.ParentID);
  const canShowGlobalScope = selectedBusiness?.ID === '9ac52e0d-99ca-4502-b85d-e9193dfc4b4c';

  const [data, setData] = useState({
    name: '',
    private: true,
    isPrivateDisabled: true,
    global: false,
  });

  useEffect(() => {
    if (!canShowGlobalScope) {
      setData({
        ...data,
        private: !canWrite,
        isPrivateDisabled: !canWrite,
      });
    } else {
      setData({
        ...data,
        private: false,
        isPrivateDisabled: true,
        global: true,
      });
    }
  }, [canShowGlobalScope, canWrite]);

  const bgColor = useMemo(() => {
    const backgroundElement = document.querySelector(`[data-section-background="${section?.id}-section-background"]`);
    const backgroundStyleValue = backgroundElement?.style?.backgroundColor?.replace('var(', '')?.replace(')', '');
    const bgColor = getComputedStyle(backgroundElement).getPropertyValue(backgroundStyleValue) || '';
    return bgColor.trim();
  }, []);

  const getImage = async () => {
    const escapedId = CSS.escape(section?.id);

    const sectionElement = document.querySelector(`[data-id="${escapedId}"][data-selector-type="section"]`);
    if (!sectionElement) return;

    const images = sectionElement.querySelectorAll('img');
    await Promise.allSettled(
      [...images].map(
        (img) =>
          new Promise((resolve, reject) => {
            if (img.complete && img.naturalHeight !== 0) {
              resolve(img);
            } else {
              img.onload = () => resolve(img);
              img.onerror = () => reject(new Error('Image failed to load'));
            }
          })
      )
    );

    const clone = sectionElement.cloneNode(true);
    sectionElement.parentElement.appendChild(clone);
    clone.style.width = 'auto';
    const { width, height } = clone.getBoundingClientRect();
    const ratio = width / height;
    let scale = 1;
    if (ratio > 3.5) {
      clone.style.width = '450px';
    } else if (ratio > 2) {
      clone.style.width = '500px';
    } else if (ratio < 1) {
      const padding = 100 * ratio;
      clone.style.padding = `0 ${padding}px`;
    }

    clone.style.position = 'absolute';
    clone.style.top = '-9999px';
    clone.style.left = '-9999px';
    clone.style.zIndex = '-1';

    const canvas = await html2canvas(clone, {
      useCORS: true,
      backgroundColor: bgColor || null,
      scale,
    });
    sectionElement.parentElement.removeChild(clone);
    canvasRef.current = canvas;
    const imgData = canvas.toDataURL('image/png');
    setScreenshot(imgData);
  };

  useEffect(() => {
    getImage();
  }, []);

  const saveSection = async () => {
    try {
      setIsSaving(true);
      const layout_v2 = Content.getSectionContent(content, section?.id);
      if (!layout_v2) {
        toast.error(`Unable to get section content, try again.`);
        return;
      }

      const thumbnail = await uploadScreenshot(canvasRef?.current, data?.name);
      if (!thumbnail) {
        toast.error(`Unable to upload thumbnail, try again.`);
        return;
      }

      const scope = getShareScope(data, selectedBusiness);

      const config = { headers: { 'Content-Profile': 'brite_public' } };
      const body = {
        ...scope,
        name: data?.name,
        layout: null,
        layout_v2,
        thumbnail_url: thumbnail?.PublicURL,
      };
      optimisticQuery.update((old) => [...old, body]);
      const url = `/v1/bp/course_editor_block`;
      await CustomAxios.post(url, body, config);
    } catch {
      optimisticQuery.revert();
      toast.error('Error saving section');
    } finally {
      setIsSaving(false);
      query.refetch();
      onClose();
    }
  };

  return (
    <Modal display={true} onClose={onClose} data-scope="modal">
      <Modal.Paper>
        <Modal.Header title="Save Section" onClose={onClose} />
        <Modal.Body>
          <Box
            css={`
              display: grid;
              gap: 16px;
            `}
          >
            <Input
              value={data?.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
              placeholder="Section name"
            />
            <Box
              css={`
                position: relative;
                width: 100%;
                height: 300px;
                overflow: hidden;
                ${container.box}
                background-color: ${bgColor};
                border-radius: 16px;
                ${shadows.lg}
                img {
                  object-fit: cover;
                  object-position: top center;
                  width: 100%;
                  height: auto;
                  max-height: 100%;
                }
              `}
              flex="center"
            >
              <img src={screenshot} onLoad={() => debounce_setIsLoadingImg(false)} />
              <BriteLoader isLoading={isLoadingImg} />
            </Box>
            <Text helper>
              If the thumbnail doesn't look quite right, exit this modal and try increasing the width of your browser
              window, then come back.
            </Text>
            {data?.isPrivateDisabled || canShowGlobalScope ? null : (
              <Box flex="space-between">
                <Text label>Make Section Private</Text>
                <Toggle checked={data?.private} onChange={(e) => setData({ ...data, private: e.target.checked })} />
              </Box>
            )}
            <Collapse isOpen={canShowGlobalScope}>
              <Box
                css={`
                  border-radius: 8px;
                  border: 2px solid ${colors.purple};
                  padding: 0 8px;
                  margin: 0 -8px;
                `}
                flex="space-between"
              >
                <Text label>Make Section Global</Text>
                <Toggle checked={data?.global} onChange={(e) => setData({ ...data, global: e.target.checked })} />
              </Box>
            </Collapse>
          </Box>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={saveSection} disabled={!data?.name || isSaving}>
            Save
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
